<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ deviceName }}</h1>

    <device-status v-if="deviceId" :device-id="deviceId" />
  </article>
</template>

<script>
import DeviceStatus from "@/components/devices/DeviceStatus.vue";

export default {
  name: "DeviceView",
  components: { DeviceStatus },
  data() {
    return {
      deviceId: "",
    };
  },
  computed: {
    deviceName() {
      return this.deviceId ? this.deviceId : "";
    },
  },
  mounted() {
    this.deviceId = this.$route.params.id;
  },
};
</script>
